<template>
  <b-card
    class="ecommerce-card"
    no-body
  >
    <div class="item-img text-center pt-0">
      <b-link>
        <b-img
          v-b-modal.modal-xs
          fluid
          class="card-img-top"
          :src="image.src_image + '?v=' + new Date().getTime()"
        />
      </b-link>
    </div>

    <slot name="blockActions" />

  </b-card>
</template>

<script>
import {
  VBTooltip, BCard, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BLink,
    BImg,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    image: {
      type: Object,
      default: () => { },
    },
  },
  computed: {
  },
  methods: {
  },
}
</script>
